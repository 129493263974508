import React, { useEffect, useRef, useState } from "react";
import "./AboutUs.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

import banner_image_1 from "../assets/banner-images/banner_image_1.jpg";
import banner_image_2 from "../assets/banner-images/banner_image_2.jpg";
import banner_image_3 from "../assets/banner-images/banner_image_3.jpg";

import ReactGA from "react-ga";

ReactGA.initialize("G-S36GWHZVC9");

const AboutUs = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [banner_image_1, banner_image_2, banner_image_3];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <Header />

      <div className='about-us-container'>
        <div className='about-us-banner'>
          <img
            src={images[currentImageIndex]}
            alt='Sliding Image'
            className='banner-image'
          />

          <div className='banner-content'>
            <div className='quote-container'>
              <h1>About Us</h1>
              <h2>
                InterioX <br /> Transforming Spaces, Creating Dreams
              </h2>
            </div>
          </div>
        </div>

        <p>
          Welcome to InterioX, the premier interior design brand providing at
          the most<u>affordable prices</u>. We take pride in our ability to
          craft stunning and functional spaces that elevate the lives of our
          valued clients.
        </p>
        <p>
          With a team of skilled designers and craftsmen, we work closely with
          our clients to transform their vision into reality. Our expertise in
          interior design and meticulous attention to detail allow us to create
          customized solutions that beautifully reflect the unique style and
          personality of each client.
        </p>
        <p>
          At InterioX, we firmly believe that every space possesses the power to
          inspire and uplift. Whether it's a residential project or a commercial
          establishment, our dedicated team is committed to delivering
          exceptional results that seamlessly blend aesthetics with
          functionality.
        </p>
        <p>
          What sets us apart is our unwavering commitment to providing the best
          interior solutions at the most affordable prices in town. We
          understand the importance of cost-effectiveness without compromising
          on quality. By streamlining our processes and leveraging our extensive
          network, we ensure that our clients receive unmatched value for their
          investment.
        </p>
        <p>
          Choose InterioX for the perfect combination of impeccable design,
          unparalleled craftsmanship, and budget-friendly solutions. Let us
          transform your space into a masterpiece that exceeds your
          expectations.
        </p>

        <p>
          We take pride in delivering exceptional quality, on-time project
          completion, and excellent customer service. Your satisfaction is our
          top priority.
        </p>
        <p>
          To learn more about our services or to discuss your interior design
          needs, <a href='/contact'>contact us</a> today.
        </p>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AboutUs;
