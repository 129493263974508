import React, { useEffect, useState } from "react";
import "./HomeInteriorInspiration.css"; // Import the CSS file

import Header from "../components/Header";
import ImageModal from "../components/ImageModal";

import ReactGA from "react-ga";

ReactGA.initialize("G-S36GWHZVC9");

const HomeInteriorInspiration = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const homes = [
    { homeName: "Home 1", imageCount: 17 },
    { homeName: "Home 2", imageCount: 11 },
    { homeName: "Home 3", imageCount: 4 },
    { homeName: "Home 4", imageCount: 6 },
    { homeName: "Home 5", imageCount: 5 },
    { homeName: "Home 6", imageCount: 16 },
    { homeName: "Home 7", imageCount: 4 },
  ];

  const [selectedHome, setSelectedHome] = useState(homes[0]);

  const handleHomeButtonClick = (home) => {
    setSelectedHome(home);
  };

  useEffect(() => {
    setImageUrls([]);

    generateUrls();
  }, [selectedHome]);

  const baseUrl = "https://interiox-india.s3.ap-south-1.amazonaws.com";

  const [imageUrls, setImageUrls] = useState([]);

  const generateUrls = () => {
    const index = homes.findIndex(
      (home) => home.homeName === selectedHome.homeName
    );

    const houseFolder = `full+house+${index + 1}`;
    const houseFile = `house${index + 1}`;

    for (let i = 1; i <= selectedHome.imageCount; i++) {
      const imageUrl = `${baseUrl}/${houseFolder}/${houseFile}+(${i}).jpg`;
      setImageUrls((prevUrls) => [...prevUrls, imageUrl]);
    }
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className='home-interior-inspiration'>
      {/* header */}
      <Header />

      <div className='home-buttons-inspiration'>
        {homes.map((home) => (
          <button
            key={home.homeName}
            className={`home-button-inspiration ${
              selectedHome.homeName === home.homeName ? "active" : ""
            }`}
            onClick={() => handleHomeButtonClick(home)}
          >
            {home.homeName}
          </button>
        ))}
      </div>
      <div className='home-images-grid-inspiration'>
        {imageUrls.map((imageUrl, index) => (
          <div
            className='home-image-inspiration'
            key={index}
            onClick={() => handleImageClick(imageUrl)}
          >
            <img
              src={imageUrl}
              alt={`Home ${selectedHome} Image ${index + 1}`}
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <ImageModal imageUrl={selectedImage} onClose={closeModal} />
      )}
    </div>
  );
};

export default HomeInteriorInspiration;
