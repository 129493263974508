import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FaPhone, FaEnvelope, FaWhatsapp } from "react-icons/fa";
import "./ContactScreen.css";

import ReactGA from "react-ga";
import ConsultationAction from "../components/ConsultationAction";
import AddressDiv from "../components/AddressDiv";

ReactGA.initialize("G-S36GWHZVC9");

const ContactScreen = () => {
  //

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [referralCode, setReferralCode] = useState("");

  useEffect(() => {
    const oldRefCode = getReferralCode();

    if (oldRefCode) {
      setReferralCode(oldRefCode);
    }
  }, []);

  const getReferralCode = () => {
    const storedData = localStorage.getItem("REFERRAL_DATA");

    // WILL CHECK IF THERE IS REFERRAL CODE

    if (storedData) {
      const data = JSON.parse(storedData);

      // CHECK IF IT IS NOT EXPIRED
      if (data.expirationTimestamp > Date.now()) {
        return data.referralCode;
      } else {
        localStorage.removeItem("REFERRAL_DATA");
      }
    }

    // RETURN NULL
    return null;
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const phoneNumber = "+917892385607"; // Replace with your phone number

  const handleDialerClick = () => {
    window.location.href = `tel:${phoneNumber}`;

    ReactGA.event({
      category: "contact",
      action: "our_phone_number_clicked",
    });
  };

  const Hello = referralCode
    ? `Hi! Interested in interior design services and have a referral code: ${referralCode}`
    : "Hi there.";

  const connectWARef = `https://wa.me/917892385607?text=${Hello}`;

  const handleWhatsappConnect = () => {
    window.location.href = connectWARef;

    ReactGA.event({
      category: "contact",
      action: "whatsapp_connect_clicked",
    });
  };

  const handleMail = () => {
    window.location.href = "mailto:contact@interiox.in";

    ReactGA.event({
      category: "contact",
      action: "mail_clicked",
    });
  };

  return (
    <div className='contact-screen-container'>
      <Header />

      <div className='contact-screen-content'>
        <h1>Contact Us</h1>

        {referralCode && <p>Referral Code: {referralCode}</p>}

        <p className='contact-screen-subtitle'>
          Feel free to reach out to us for any inquiries or to schedule a free
          consultation.{" "}
          {referralCode && (
            <p>
              Please mention the referral code in case of phone call so that the
              referral gets a reward 🤗.
            </p>
          )}
        </p>

        <div className='contact-screen-info'>
          <div className='contact-screen-methods'>
            <div className='contact-screen-method'>
              <FaPhone className='contact-screen-icon' />
              <span href={`tel:${phoneNumber}`} onClick={handleDialerClick}>
                Phone: +917892385607
              </span>
            </div>
            <div className='contact-screen-method'>
              <FaEnvelope className='contact-screen-icon' />
              <span
                target='_blank'
                href='mailto:contact@interiox.in'
                onClick={handleMail}
              >
                Email: contact@interiox.in
              </span>
            </div>

            <a className='contact-screen-method'>
              <FaWhatsapp className='contact-screen-icon' />
              <span href={connectWARef} onClick={handleWhatsappConnect}>
                Chat On WhatsApp
              </span>
            </a>

            <div className='contact-screen-method'>
              <ConsultationAction
                buttonName='Request Callback'
                referralCode={referralCode}
              />
            </div>
          </div>
        </div>
        <AddressDiv />
      </div>

      <Footer />
    </div>
  );
};

export default ContactScreen;
