import React, { useState, useEffect } from "react";
import "./CallToActionCircle.css";

// assets
import designerConsultGif from "../assets/designer.gif";
import constructionGif from "../assets/construction.gif";
import installationGif from "../assets/installation.gif";
import moveInGif from "../assets/happyhome.gif";
import ConsultationAction from "./ConsultationAction";
import ContactButton from "./ContactButton";

const CallToActionCircle = () => {
  const [titleIndex, setTitleIndex] = useState(1);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Calculate the index of the next circle
      const nextIndex = titleIndex === 4 ? 1 : titleIndex + 1;
      setTitleIndex(nextIndex);
    }, 6500);

    return () => {
      clearTimeout(timer);
    };
  }, [titleIndex]);

  return (
    <div className='call-to-action'>
      {titleIndex === 1 ? (
        <div className='call-to-action-container'>
          <div>
            <img
              className='gif-image'
              src={designerConsultGif}
              alt='consult designer'
            />
          </div>
          <h2 className='call-to-action-title'>
            1. Meet our designer and finalize the design (5% payment)*
          </h2>
        </div>
      ) : titleIndex === 2 ? (
        <div className='call-to-action-container'>
          <div>
            <img
              className='gif-image'
              src={constructionGif}
              alt='Execution of the interior'
            />
          </div>
          <h2 className='call-to-action-title'>
            2. Execution begins (60% payment)
          </h2>
        </div>
      ) : titleIndex === 3 ? (
        <div className='call-to-action-container'>
          <div>
            <img
              className='gif-image'
              src={installationGif}
              alt='Final installation and quality check'
            />
          </div>
          <h2 className='call-to-action-title'>
            3. Final installation and quality check (100% payment)
          </h2>
        </div>
      ) : (
        <div className='call-to-action-container'>
          <div>
            <img
              className='gif-image'
              src={moveInGif}
              alt='move in to your happy home'
            />
          </div>
          <h2 className='call-to-action-title'>
            4. Move in to your happy home
          </h2>
        </div>
      )}

      <ContactButton actionName='Book Free Consultation' />
    </div>
  );
};

export default CallToActionCircle;
