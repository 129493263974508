import React from "react";
import "./ImageModal.css";

const ImageModal = ({ imageUrl, onClose }) => {
  return (
    <div className='image-modal-overlay' onClick={onClose}>
      <div className='image-modal-content'>
        <img src={imageUrl} className='modal-image' />
        <button className='modal-close' onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ImageModal;
