import React from "react";
import "./YouTubeVideo.css";

const YouTubeVideo = ({ videoId = "jEM0bX10M50" }) => {
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className='youtube-video'>
      <iframe
        width='560'
        height='315'
        src={embedUrl}
        title='YouTube Video Player'
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeVideo;
