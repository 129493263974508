import React, { useState } from "react";
import "./ConsultationAction.css";

import ContactForm from "./ContactForm";

import ReactGA from "react-ga";

ReactGA.initialize("G-S36GWHZVC9");

const ConsultationAction = ({
  buttonName = "",
  buttonType = "PRIMARY",
  referralCode = "",
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleModalToggle = () => {
    if (showModal === false) {
      ReactGA.event({
        category: "contact",
        action: "opened_contact_form",
      });
    }

    setShowModal(!showModal);
  };

  return (
    <div className='consultation-action'>
      <button
        className={`contact-options-button ${
          buttonType === "SECONDARY" ? "secondary-button" : ""
        }`}
        onClick={handleModalToggle}
      >
        {buttonName}
      </button>

      {showModal && (
        <div className='modal'>
          <div className='modal-content'>
            <div class='image-container'>
              <button className='close-button' onClick={handleModalToggle}>
                x
              </button>
            </div>

            <ContactForm referralCode={referralCode} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ConsultationAction;
