import React, { useState, useEffect, useRef } from "react";
import "./BrandMarquee.css";

import image1 from "../assets/brands/asian_paint.png";
import image2 from "../assets/brands/berger.png";
import image3 from "../assets/brands/gobin.png";
import image4 from "../assets/brands/hafele.png";
import image5 from "../assets/brands/hettich.png";
import image6 from "../assets/brands/kajaria.png";
import image7 from "../assets/brands/centuryply.png";
import image8 from "../assets/brands/greenply.png";
import image9 from "../assets/brands/greenlam.png";
import image10 from "../assets/brands/jaquar.png";
import image11 from "../assets/brands/pidilite.png";
import image12 from "../assets/brands/faber.png";
import image13 from "../assets/brands/franke.png";
import image14 from "../assets/brands/royal-touch.png";
import image15 from "../assets/brands/Kiya+Lam.png";
import image16 from "../assets/brands/tajpuria.png";

const Marquee = () => {
  const marqueeRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollSpeed = 2; // Adjust the scroll speed as needed

  useEffect(() => {
    const interval = setInterval(() => {
      const containerWidth = marqueeRef.current.clientWidth;
      const contentWidth = marqueeRef.current.scrollWidth;
      const newPosition = scrollPosition + scrollSpeed;

      if (newPosition > contentWidth - containerWidth) {
        setScrollPosition(0);
      } else {
        setScrollPosition(newPosition);
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [scrollPosition]);

  const images = [
    image7,
    image8,
    image9,
    image4,
    image5,
    image3,
    image6,
    image10,
    image1,
    image2,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
  ];

  return (
    <div className='marquee-container'>
      <div className='marquee' ref={marqueeRef}>
        {images.map((image, index) => (
          <div className='marquee-image-container' key={index}>
            <img
              src={image}
              className='marquee-image'
              alt={`Image ${index + 1}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Marquee;
