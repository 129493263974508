import React from "react";
import "./AddressDiv.css"; // Import the CSS file

function AddressDiv() {
  const address =
    "Housing Colony && Timber Yard Behind Satellite Bus-stand, Bengaluru, Bengaluru Urban, Karnataka, 560040";

  return (
    <div className='address-div'>
      <h3>Address:</h3>
      <span>{address}</span>
    </div>
  );
}

export default AddressDiv;
