import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

import HomeScreen from "./screens/HomeScreen";
import ContactScreen from "./screens/ContactScreen";
import NotFoundScreen from "./screens/NotFoundScreen";
import AboutUs from "./screens/AboutUs";
import SolutionScreen from "./screens/SolutionScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import SinglePostScreen from "./screens/SinglePostScreen";
import HomeInteriorInspiration from "./screens/HomeInteriorInspiration";
import ReferralPage from "./screens/ReferralPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "contact",
    element: <ContactScreen />,
  },
  {
    path: "post/:postId",
    element: <SinglePostScreen />,
  },
  {
    path: "about",
    element: <AboutUs />,
  },
  {
    path: "solutions",
    element: <SolutionScreen />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicyScreen />,
  },
  {
    path: "homes",
    element: <HomeInteriorInspiration />,
  },
  {
    path: "earn",
    element: <ReferralPage />,
  },
  {
    path: "*",
    element: <NotFoundScreen />,
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
