// SinglePostScreen.js
import React, { useState, useEffect } from "react";

import "./SinglePostScreen.css";

import { useParams } from "react-router-dom";

import axios from "axios";

import Header from "../components/Header";
import Footer from "../components/Footer";
import SocialMediaPost from "../components/SocialMediaPost";
import ConsultationAction from "../components/ConsultationAction";

import { Helmet } from "react-helmet";

const SinglePostScreen = () => {
  const { postId } = useParams();

  const [post, setPost] = useState(null);

  const [loading, setLoading] = useState(true);

  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const getSinglePost = async () => {
      try {
        setLoading(true);
        setHasError(false);
        setErrorMessage("");

        const response = await axios.post(
          `https://api.oocuss.com/api/commercial/embedded/post/${postId}`,
          {
            mobileNumber: "+919148571704",
            secretApiKey: "0RDMAI421HQA",
            clubId: "club_interiox",
          }
        );

        setPost(response.data);

        // get the post here

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setHasError(true);
        setErrorMessage(error.response.data || "Couldn't fetch posts :(");
      }
    };

    getSinglePost();
  }, [postId]);

  return (
    <div className='singlepost-container'>
      <Header />

      {/* helmet */}

      <div className='singlepost-content'>
        <h2>
          Post from c/club_interiox on{" "}
          <a href='https://www.oocuss.com/' target='_blank' rel='noreferrer'>
            Oocuss
          </a>
        </h2>

        {/* post */}

        {errorMessage && (
          <div style={{ height: "50vh", alignSelf: "center" }}>
            Loading Post...
          </div>
        )}

        {loading && (
          <div style={{ height: "50vh", alignSelf: "center" }}>
            Loading Post...
          </div>
        )}

        {!loading && !hasError && post && (
          <div>
            <Helmet>
              <title>{post.authorDetails.name}'s Post in c/club_interiox</title>
              <meta
                property='og:title'
                content={`${post.authorDetails.name}'s Post in c/club_interiox`}
              />
              <meta property='og:description' content={post.description} />
              <meta property='og:image' content={post.mediaSource[0].url} />
              {/* Add other meta tags as needed */}
            </Helmet>

            <SocialMediaPost post={post} />
          </div>
        )}

        <ConsultationAction buttonName='Contact Our Designer' />
      </div>

      <Footer />
    </div>
  );
};

export default SinglePostScreen;
