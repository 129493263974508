import React, { useState } from "react";
import "./SocialMediaPost.css";

import { FaShare } from "react-icons/fa";

const SocialMediaPost = ({ post }) => {
  const { authorDetails: author, mediaSource: images } = post;

  const [modalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentImageIndex(0);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const highlightHashtags = (description) => {
    const hashtagRegex = /#[^\s#]+/g;
    return description.replace(
      hashtagRegex,
      (match) => `<span class="hashtag">${match}</span>`
    );
  };

  const indicatorText = `1/${images.length}`;

  const socialShareUrl = `https://www.interiox.in/post/${post._id}`;

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check out this new design on Interiox!",
          text: post.description,
          url: socialShareUrl,
          icon: images[0].url,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Your browser does not support sharing.");
    }
  };

  return (
    <div className='social-media-post'>
      <div className='author'>
        <img src={author.profilePic} alt='Author Profile' />
        <div>
          <p className='author-name'>{author.name}</p>
          <p className='author-user-id'>{author.userIds.slice(-1)[0].userId}</p>
        </div>

        <div className='share-icon' onClick={handleShare}>
          <FaShare style={{ fontSize: 14 }} />
        </div>
      </div>
      <div
        className='description'
        dangerouslySetInnerHTML={{
          __html: highlightHashtags(post.description),
        }}
      ></div>

      <div className='images'>
        <div className='first-image-container' onClick={openModal}>
          <img src={images[0].url} alt={`Image 1`} />
          {images.length > 1 && (
            <div className='image-indicator'>{indicatorText}</div>
          )}
        </div>
      </div>

      {modalOpen && (
        <div className='image-modal'>
          <div className='image-modal-content'>
            {images.length > 1 && (
              <button className='modal-prev-button' onClick={handlePrevImage}>
                &lt;
              </button>
            )}
            <img
              src={images[currentImageIndex].url}
              alt={`Image ${currentImageIndex + 1}`}
              className='modal-image'
            />
            {images.length > 1 && (
              <button className='modal-next-button' onClick={handleNextImage}>
                &gt;
              </button>
            )}
          </div>
          <button className='modal-close-button' onClick={closeModal}>
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default SocialMediaPost;
