import React, { useEffect, useState } from "react";

import "./HomeScreen.css";
import "./Fonts.css";

import interiox from "../assets/interiox.png";

import bestPrice from "../assets/best-price.png";
import quality from "../assets/quality.png";
import delivery from "../assets/delivery.png";

import ContactForm from "../components/ContactForm";
import CallToActionCircle from "../components/CallToActionCircle";
import Exhibition from "../components/Exhibition";
import BrandMarquee from "../components/BrandMarquee";

import banner_image_1 from "../assets/banner-images/banner_image_1.jpg";
import banner_image_2 from "../assets/banner-images/banner_image_2.jpg";
import banner_image_3 from "../assets/banner-images/banner_image_3.jpg";
import SavingsBreakdown from "../components/SavingsBreakdown";
import ContactNow from "../components/ContactNow";
import Footer from "../components/Footer";
import InteriorComponents from "../components/InteriorComponents";
import OocussAPIComponent from "../components/OocussAPIComponent";
import YouTubeVideo from "../components/YouTubeVideo";
import { Helmet } from "react-helmet";

import bangaloreDivision from "../assets/divisions/bangalore.jpg";
import belagaviDivision from "../assets/divisions/division_2.png";
import goaDivision from "../assets/divisions/goa.jpg";
import gulbargaDivision from "../assets/divisions/gulbarga.jpg";

import { Link } from "react-router-dom";

import { FaPhoneAlt } from "react-icons/fa";

import ReactGA from "react-ga";

import ContactButton from "../components/ContactButton";
import LocationDivision from "../components/LocationDivision";

import { useLocation } from "react-router-dom";
import axios from "axios";
import ReferralEarnBanner from "../components/ReferralEarnBanner";
import AddressDiv from "../components/AddressDiv";

ReactGA.initialize("G-S36GWHZVC9");

const HomeScreen = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const refValue = searchParams.get("ref");

  const [referralCode, setReferralCode] = useState("");

  useEffect(() => {
    handleReferralCode();
  }, [refValue]);

  const handleReferralCode = async () => {
    try {
      /**
       * First we need to check if there is ref code already.
       *
       * If there is one and has not expired, then don't store the new ref.
       * If not, store the new ref code.
       *
       */

      const oldRefCode = getReferralCode();

      if (oldRefCode) {
        setReferralCode(oldRefCode);

        return;
      }

      if (refValue) {
        /**
         * if we get the ref value, we need to store in the the local storage with one week expiration time
         */

        const expirationTimestamp = Date.now() + 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
        const data = {
          referralCode: refValue,
          expirationTimestamp,
        };

        // we need to check if the referral code is valid

        const response = await axios.get(
          `https://confrets.oocuss.com/api/interiox/get/referral/${refValue}`
        );

        // if we get response then we have to save the field else no

        if (response.data) {
          setReferralCode(refValue);

          localStorage.setItem("REFERRAL_DATA", JSON.stringify(data));
        }
      }
    } catch (error) {
      console.log("something went wrong. Please try again later");
    }
  };

  const getReferralCode = () => {
    const storedData = localStorage.getItem("REFERRAL_DATA");

    // WILL CHECK IF THERE IS REFERRAL CODE

    if (storedData) {
      const data = JSON.parse(storedData);

      // CHECK IF IT IS NOT EXPIRED
      if (data.expirationTimestamp > Date.now()) {
        return data.referralCode;
      } else {
        localStorage.removeItem("REFERRAL_DATA");
      }
    }

    // RETURN NULL
    return null;
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [banner_image_1, banner_image_2, banner_image_3];

  const phoneNumber = "+917892385607"; // Replace with your phone number

  const handleDialerClick = () => {
    window.location.href = `tel:${phoneNumber}`;

    ReactGA.event({
      category: "contact",
      action: "our_phone_number_clicked",
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>InterioX</title>
        <meta property='og:title' content={"InterioX"} />
        <meta
          property='og:description'
          content={"Transforming Spaces, Creating Dreams 🤍"}
        />
        <meta
          property='og:image'
          content='https://oocuss-media-01.s3.ap-south-1.amazonaws.com/9B92E2E5-B391-439C-9BFC-825FB1ADB70F.jpg'
        />
        {/* Add other meta tags as needed */}
      </Helmet>

      <header className='header-style'>
        <div className='logo-style'>
          <img src={interiox} alt='InterioX Logo' className='logo-img-style' />
          <h2 className='brand-name'>InterioX</h2>
        </div>

        <div className='header-button-container'>
          <a
            style={{ margin: "17px" }}
            href={`tel:${phoneNumber}`}
            onClick={handleDialerClick}
          >
            <FaPhoneAlt style={{ color: "white", fontSize: "20px" }} />
          </a>

          <ContactButton />
        </div>
      </header>

      <div className='content'>
        <div className='banner'>
          <img
            src={images[currentImageIndex]}
            alt='Sliding Image'
            className='banner-image'
          />

          <div className='banner-content'>
            <div className='quote-container'>
              <h3 className='quote'>
                Affordable Modular Interiors within 35 Days🤍
              </h3>
              <p className='quote-sub'>
                Let our skilled designers create a space that reflects your
                unique style and personality.
              </p>
            </div>

            <div className='contact-form-container'>
              <ContactForm referralCode={referralCode} />
            </div>
          </div>
        </div>

        <h3 className='why-interiox-title'>
          Join Our Referral Program and Earn upto ₹20,000 Every Time You Refer
          Us 🥳.
        </h3>

        <ReferralEarnBanner />

        <h3 className='why-interiox-title'>We Are Operating Successfully in</h3>

        {/* <div>{ref}</div> */}

        <div className='division-container'>
          <LocationDivision
            locationName={"Bangalore 🌆"}
            imageUrl={bangaloreDivision}
          />
          <LocationDivision
            locationName={"Belagavi 🏞️"}
            imageUrl={belagaviDivision}
          />
          <LocationDivision locationName={"Goa 🏖️"} imageUrl={goaDivision} />
          <LocationDivision
            locationName={"Gulbarga 🙏"}
            imageUrl={gulbargaDivision}
          />
        </div>

        <h3 className='why-interiox-title'>Why Us ?</h3>

        <div className='why-interiox'>
          <div className='point'>
            <img src={bestPrice} alt='best-price' className='point-image' />
            <div className='point-content'>
              <h3>Best Price</h3>
              Unlock Affordable Pricing: Transform Your{" "}
              <span className='highlight'>2BHK Interior Just @₹2.5L*</span> &
              Get Guaranteed <span className='highlight'>20% Less Quote</span>{" "}
              For Your Interior Designs Than Others 🤩.
            </div>
          </div>
          <div className='point'>
            <img
              src={quality}
              alt='top-quality'
              className='point-image-quality'
            />
            <div className='point-content'>
              <h3>Superior Quality</h3>
              <span>
                Quality Materials, Expert Craftsmanship.{" "}
                <span className='highlight'>10-Year Warranty</span>* for Your
                Peace of Mind.
              </span>
            </div>
          </div>
          <div className='point'>
            <img
              src={delivery}
              alt='on-time-delivery'
              className='point-image'
            />

            <div className='point-content'>
              <h3>Superfast Execution</h3>
              <span>
                Guaranteed project delivery in{" "}
                <span className='highlight'>
                  35 Days* or receive ₹1000 Per Day
                </span>
                . Your Time, Our Commitment.
              </span>
            </div>
          </div>
        </div>

        <div className='exhibition-dev'>
          <p>
            Why we are <span className='highlight'>Soooooo</span> Affordable!
          </p>

          <SavingsBreakdown />
        </div>

        <div className='call-for-action'>
          <h3>How Simple it is ?</h3>
          <p>
            Let's start building your dream house just following these{" "}
            <span className='highlight' style={{ color: "white" }}>
              4 simple steps
            </span>
          </p>

          <CallToActionCircle />
        </div>

        <div className='exhibition-dev'>
          <p>
            We Breath Designs That{" "}
            <span className='highlight'>You Live For</span>
          </p>

          <a
            href='https://photos.app.goo.gl/EnLwpqQjJaEAbsSg7'
            className='view-all-button'
            target='_blank'
          >
            View All Designs
          </a>

          <Exhibition />

          <div style={{ marginTop: "10px" }}>
            <ContactButton actionName='Talk To A Designer' />
          </div>
        </div>

        {/* OOCUSS POSTS */}
        <OocussAPIComponent />
        {/* <div className='call-for-action'>
          <h3>Where the hearts spoke</h3>
          <Testimony />
        </div> */}

        <h2 style={{ margin: "20px" }}>
          Our Interior Manufacturing Unit in Bangalore
        </h2>
        <YouTubeVideo />

        <div className='exhibition-dev'>
          <p>Services Provided By Us</p> <Link to='/solutions'>View All</Link>
          <InteriorComponents />
          <div style={{ marginTop: "20px" }}>
            <ContactButton actionName='Contact Interiox' />
          </div>
        </div>

        <div className='call-for-action'>
          <h3>Our Brand Partners</h3>
          <BrandMarquee />
        </div>

        {/* contact now */}
        <div className='exhibition-dev'>
          <p>React Us</p>
          <span>
            Your dream abode is just a click away from becoming a reality
          </span>

          <ContactNow />
        </div>

        <div style={{ textAlign: "center", marginBottom: 30 }}>
          <AddressDiv />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HomeScreen;
