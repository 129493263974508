import React from "react";
import "./Header.css";

import { Link } from "react-router-dom";

import interiox from "../assets/interiox.png";

import ConsultationAction from "./ConsultationAction";

import { FaPhoneAlt } from "react-icons/fa";

import ReactGA from "react-ga";
import ContactButton from "./ContactButton";

ReactGA.initialize("G-S36GWHZVC9");

const Header = () => {
  const phoneNumber = "+917892385607"; // Replace with your phone number

  const handleDialerClick = () => {
    window.location.href = `tel:${phoneNumber}`;

    ReactGA.event({
      category: "contact",
      action: "our_phone_number_clicked",
    });
  };

  return (
    <header className='header-style'>
      <Link to='/'>
        <div className='logo-style'>
          <img
            src={interiox}
            alt='InteriorIo Logo'
            className='logo-img-style'
          />
          <h2 className='brand-name'>InterioX</h2>
        </div>
      </Link>
      <div className='header-button-container'>
        <a
          style={{ margin: "17px" }}
          href={`tel:${phoneNumber}`}
          onClick={handleDialerClick}
        >
          <FaPhoneAlt style={{ color: "white", fontSize: "20px" }} />
        </a>

        <ContactButton actionName='Contact Us' />
      </div>
    </header>
  );
};

export default Header;
