import React from "react";

import "./InteriorComponents.css";

const InteriorComponents = () => {
  const services = [
    {
      icon: require("../assets/all-services/dining.png"),
      service: "Dining Room",
    },
    {
      icon: require("../assets/all-services/balcony.png"),
      service: "Balcony",
    },
    {
      icon: require("../assets/all-services/kitchen.png"),
      service: "Kitchen",
    },
    {
      icon: require("../assets/all-services/pooja.png"),
      service: "Pooja Unit",
    },
    {
      icon: require("../assets/all-services/tv-unit.png"),
      service: "TV Unit",
    },
    {
      icon: require("../assets/all-services/wordrobe.png"),
      service: "Wardrobe",
    },
    {
      icon: require("../assets/all-services/bedroom.png"),
      service: "Bedroom",
    },
    {
      icon: require("../assets/all-services/hotel.png"),
      service: "Restaurants",
    },
    {
      icon: require("../assets/all-services/offices.png"),
      service: "Office Setup",
    },
    {
      icon: require("../assets/all-services/sofas.png"),
      service: "Sofas",
    },
    {
      icon: require("../assets/all-services/false-cealing.png"),
      service: "False Ceiling",
    },
    {
      icon: require("../assets/all-services/painting.png"),
      service: "Painting",
    },
    {
      icon: require("../assets/all-services/wallpaper.png"),
      service: "Wallpaper",
    },
    {
      icon: require("../assets/all-services/house-designing.png"),
      service: "2D & 3D Design",
    },
    {
      icon: require("../assets/all-services/tiles.png"),
      service: "Tiles Fitting",
    },
    {
      icon: require("../assets/all-services/electric.png"),
      service: "Electrical Work",
    },
    {
      icon: require("../assets/all-services/plumbing.png"),
      service: "Plumbing Work",
    },
  ];

  return (
    <div className='other-services'>
      <div className='other-services-list'>
        {services.map((service) => (
          <div key={service.icon} className='other-service'>
            <img src={service.icon} />
            <span>{service.service}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InteriorComponents;
