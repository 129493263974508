import React, { useEffect } from "react";
import "./SolutionScreen.css";

import Header from "../components/Header";
import InteriorComponents from "../components/InteriorComponents";
import Footer from "../components/Footer";

import ReactGA from "react-ga";

ReactGA.initialize("G-S36GWHZVC9");

const SolutionScreen = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className='solution-page-container'>
        {/* header */}
        <Header />

        <img
          src={require("../assets/grid-images/collage.jpg")}
          className='grid-image-style'
        />

        <h2 className='solution-page-title'>Our Solutions</h2>
        <div className='solution-card'>
          <div className='solution-content'>
            <p className='solution-description'>
              We offer comprehensive interior design solutions that transform
              spaces and bring dreams to life. Our team of experienced designers
              and architects work closely with clients to create personalized
              and functional designs that reflect their unique style and vision.
            </p>
            <p>
              From concept development to project execution, we handle every
              aspect of the interior design process. Our solutions encompass...
            </p>

            <ul>
              <li>Residential Interior Design</li>
              <li>Commercial Interior Design</li>
              <li>Office Interior Design</li>
              <li>Restaurant Interior Design</li>
              <li>Retail Store Design</li>
              <li>Space Planning</li>
              <li>Furniture and Décor Selection</li>
            </ul>
            <p>
              With a focus on attention to detail and quality craftsmanship, we
              curate the finest materials, furniture, and finishes to create
              spaces that inspire. Our designers leverage their expertise and
              creativity to seamlessly blend elements of color, texture,
              lighting, and spatial arrangement to achieve harmonious interiors
              that enhance the overall ambiance.
            </p>
            <p>
              Whether it's a complete Home renovation, office redesign, or a
              single room makeover, InterioX is committed to delivering
              exceptional results. We prioritize open communication, client
              collaboration, and timely project delivery to ensure a smooth and
              enjoyable design journey.
            </p>
            <p>
              Experience the difference of InterioX's interior design solutions
              and let us transform your space into a sanctuary of beauty and
              functionality.
            </p>
          </div>
        </div>
      </div>

      <InteriorComponents />
      {/* Footer */}

      <Footer />
    </div>
  );
};

export default SolutionScreen;
