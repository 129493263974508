import React, { useState, useEffect } from "react";
import SocialMediaPost from "./SocialMediaPost";
import "./OocussAPIComponent.css";
import axios from "axios";

const OocussAPIComponent = () => {
  const [posts, setPosts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const getOocussPosts = async () => {
      try {
        setLoading(true);
        setHasError(false);

        const response = await axios.post(
          "https://api.oocuss.com/api/commercial/embedded/posts?skip=0",
          {
            mobileNumber: "+919148571704",
            secretApiKey: "0RDMAI421HQA",
            clubId: "club_interiox",
          }
        );

        setPosts(response.data.imagePosts);

        setLoading(false);
      } catch (error) {
        setHasError(true);
      }
    };

    getOocussPosts();
  }, []);

  return (
    <div>
      {posts.length > 0 && !loading && !hasError && (
        <div>
          <h2>
            Posts from c/club_interiox on{" "}
            <a href='https://www.oocuss.com/' target='_blank' rel='noreferrer'>
              Oocuss
            </a>
          </h2>
          <div className='post-container'>
            {posts.map((post, index) => (
              <SocialMediaPost key={index} post={post} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default OocussAPIComponent;
