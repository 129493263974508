import React from "react";

import "./ContactNow.css";
import ConsultationAction from "./ConsultationAction";

import ReactGA from "react-ga";
ReactGA.initialize("G-S36GWHZVC9");

const ContactNow = () => {
  // calling to the phone number

  const phoneNumber = "+917892385607"; // Replace with your phone number

  const handleDialerClick = () => {
    window.location.href = `tel:${phoneNumber}`;

    ReactGA.event({
      category: "contact",
      action: "our_phone_number_clicked",
    });
  };

  const Hello = "Hi there.";

  const connectWARef = `https://wa.me/917892385607?text=${Hello}`;

  const handleWhatsappConnect = () => {
    window.location.href = connectWARef;

    ReactGA.event({
      category: "contact",
      action: "whatsapp_connect_clicked",
    });
  };

  return (
    <div className='contact-now'>
      <div className='contact-options'>
        <a
          href={`tel:${phoneNumber}`}
          onClick={handleDialerClick}
          className='contact-options-button'
        >
          Call Now
        </a>

        <a
          className='contact-options-button'
          href={connectWARef}
          onClick={handleWhatsappConnect}
        >
          Connect On Whatsapp
        </a>

        <ConsultationAction
          buttonName='Request Callback'
          buttonType='SECONDARY'
        />
      </div>
    </div>
  );
};

export default ContactNow;
