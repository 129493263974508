import React from "react";

import Lottie from "lottie-react";

import savingsAnim from "../assets/savings.json";

import "./SavingsBreakdown.css";

import ContactButton from "./ContactButton";

const SavingsBreakdown = () => {
  return (
    <div className='savings-container'>
      <div className='anim-image-container'>
        <Lottie
          animationData={savingsAnim}
          loop={true}
          className='anim-image'
        />
      </div>

      <span>
        InterioX: Clear Pricing, No Hidden Charges
        <br />
        <br />
        🚫 No Celebrity Endorsement Fees (Up to{" "}
        <span class='highlight'>10% Saving</span>)
        <br />
        <br />
        🚫 No Multi-Level Outsourcing Charges (Up to{" "}
        <span class='highlight'>20% Saving</span>)
        <br />
        <br />
        💰 Your 1 Lakh Example Project will save ~
        <span class='highlight'>32,000 rupees</span> if you choose us.
        <br />
        <br />✅ With InterioX, Your Budget is Fully Allocated to Your Project
        <br />✅ Transparent, Affordable, Exceptional!
      </span>

      {/* button */}
      <div style={{ marginTop: "50px", textAlign: "center" }}>
        <h3>Ready to build your dream home at the right price?</h3>

        <ContactButton actionName='Contact InterioX' />
      </div>
    </div>
  );
};

export default SavingsBreakdown;
