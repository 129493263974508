import React, { useEffect } from "react";

import "./PrivacyPolicyScreen.css";

import Header from "../components/Header";
import Footer from "../components/Footer";

import ReactGA from "react-ga";

ReactGA.initialize("G-S36GWHZVC9");

const PrivacyPolicyScreen = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />

      <div className='privacy-policy-container'>
        <h1>Privacy Policy</h1>
        <p>
          At InterioX, we are committed to protecting the privacy and security
          of your personal information. This Privacy Policy explains how we
          collect, use, and disclose personal data when you use our services and
          interact with our website. We value your trust and want to ensure
          transparency in our data practices.
        </p>

        <h2>Collection of Personal Information</h2>
        <p>
          We may collect personal information from you when you provide it
          voluntarily, such as when you fill out a contact form, request a
          consultation, or subscribe to our newsletter. The types of personal
          information we may collect include your name, email address, phone
          number, and any other information you choose to provide.
        </p>

        <h2>Use of Personal Information</h2>
        <p>
          We use the personal information you provide to respond to your
          inquiries, fulfill your requests, provide personalized design
          services, and communicate with you about our products, promotions, and
          events. We may also use your information for internal purposes such as
          data analysis, improving our services, and enhancing user experience.
        </p>

        <h2>Protection of Personal Information</h2>
        <p>
          We take appropriate measures to safeguard the personal information we
          collect and maintain. We have implemented technical and organizational
          security measures to protect against unauthorized access, loss, or
          misuse of personal data. However, please note that no data
          transmission over the internet is entirely secure, and we cannot
          guarantee the absolute security of your information.
        </p>

        <h2>Disclosure of Personal Information</h2>
        <p>
          We may share your personal information with third-party service
          providers who assist us in operating our business and providing
          services to you. These service providers are contractually obligated
          to maintain the confidentiality and security of your personal data. We
          may also disclose your information as required by law or if we believe
          it is necessary to protect our rights, property, or safety.
        </p>

        <h2>Retention of Personal Information</h2>
        <p>
          We will retain your personal information for as long as necessary to
          fulfill the purposes outlined in this Privacy Policy or as required by
          law. We will securely dispose of personal data when it is no longer
          needed.
        </p>

        <h2>Your Rights</h2>
        <p>
          You have the right to access, update, and correct your personal
          information. You may also request the deletion of your personal data
          or withdraw your consent for certain processing activities. To
          exercise your rights or for any privacy-related inquiries, please
          contact us using the information provided at the end of this Privacy
          Policy.
        </p>

        <h2>Updates to Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our data practices or legal requirements. We encourage you to
          review this Policy periodically for any updates. Your continued use of
          our services after any changes indicate your acceptance of the updated
          Privacy Policy.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions, concerns, or requests regarding our Privacy
          Policy or our data practices, please contact us at:
        </p>
        <ul>
          <li>Phone: +917892385607</li>
          <li>Email: contact@interiox.in</li>
        </ul>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicyScreen;
