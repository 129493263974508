import React from "react";
import "./LocationDivision.css"; // Create a CSS file for styling

const LocationDivision = ({ locationName, imageUrl, isNew = false }) => {
  return (
    <div>
      <div className='location-division'>
        {isNew && <div className='new-division-container'>New</div>}
        <img src={imageUrl} alt={locationName} />
      </div>

      <h3>{locationName}</h3>
    </div>
  );
};

export default LocationDivision;
