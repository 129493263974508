import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";

import "./ReferralEarnBanner.css"; // Create a corresponding CSS file

import shareToFriendsAnim from "../assets/shareToFriend_anim.json";
import createLinkAnim from "../assets/createLink_anim.json";
import earnMoneyAnim from "../assets/earnMoney_anim.json";
import { Link } from "react-router-dom";

const ReferralEarnBanner = () => {
  const [animIndex, setAnimIndex] = useState(1);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Calculate the index of the next circle
      const nextIndex = animIndex === 3 ? 1 : animIndex + 1;
      setAnimIndex(nextIndex);
    }, 5800);

    return () => {
      clearTimeout(timer);
    };
  }, [animIndex]);

  return (
    <div className='referral-earn-banner'>
      <div className='content-container'>
        <div className='animation-container'>
          {animIndex === 1 ? (
            <Lottie
              animationData={createLinkAnim}
              className='lottie-ref-banner-animation'
              loop={false}
            />
          ) : animIndex === 2 ? (
            <Lottie
              animationData={shareToFriendsAnim}
              loop={false}
              className='lottie-ref-banner-animation'
            />
          ) : (
            <Lottie
              animationData={earnMoneyAnim}
              loop={false}
              className='lottie-ref-banner-animation'
            />
          )}
        </div>
        <div className='description-container'>
          <p className='ref-description'>
            {animIndex === 1
              ? "Generate Link 🔗..."
              : animIndex === 2
              ? "Share Link 🚀..."
              : " Earn Money 💸"}
          </p>
        </div>

        <div>
          <Link className='contact-options-button' to={"/earn"}>
            Join Referral Program
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ReferralEarnBanner;
