import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className='footer-container'>
      <div className='footer-content'>
        <div className='footer-links'>
          <Link to='/about'>About Us</Link>
          {/* <Link to='/earn'>Join Referral Program</Link> */}
          <Link to='/solutions'>Solutions</Link>
          <Link to='/contact'>Contact Us</Link>
          <Link to='/privacy-policy'>Privacy Policy</Link>
        </div>
        <div className='footer-credits'>
          <p>&copy; 2023 InterioX. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
