import React from "react";

import { Link } from "react-router-dom";

const ContactButton = ({ actionName = "Get Free Consultation" }) => {
  return (
    <div>
      <Link className='contact-options-button' to={"/contact"}>
        {actionName}
      </Link>
    </div>
  );
};

export default ContactButton;
