// 63 people before ad

import React, { useEffect, useState } from "react";
import "./ReferralPage.css";

import Header from "../components/Header";

import Lottie from "lottie-react";

import successAnim from "../assets/success_anim.json";

import shareToFriendsAnim from "../assets/shareToFriend_anim.json";
import createLinkAnim from "../assets/createLink_anim.json";
import earnMoneyAnim from "../assets/earnMoney_anim.json";
import socialMediaIcons from "../assets/socialMediaIcons_anim.json";

import axios from "axios";
import PhoneInput from "react-phone-number-input";
import { FaWhatsapp } from "react-icons/fa";

import ReactGA from "react-ga";

ReactGA.initialize("G-S36GWHZVC9");

const ReferralPage = () => {
  //

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    city: "Bengaluru", // Default city
    agreeToPolicy: false,
  });

  const [confirmMobileNumber, setConfirmMobileNumber] = useState("");

  const [count, setCount] = useState(0);

  useEffect(() => {
    getUserCount();
  }, []);

  const getUserCount = async () => {
    try {
      const response = await axios.get(
        "https://confrets.oocuss.com/api/interiox/get/referral-count"
      );

      setCount(response.data.totalCount);
    } catch (error) {
      setCount(0);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const [referralLink, setReferralLink] = useState("");

  const [generatingLink, setGeneratingLink] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (!formData.mobile) {
        alert("Please enter a valid mobile number");
        return;
      }

      if (formData.mobile !== confirmMobileNumber) {
        alert("Your confirmation mobile number didn't match");
        return;
      }

      setGeneratingLink(true);

      // creating the referral user

      const response = await axios.post(
        "https://confrets.oocuss.com/api/interiox/register/referral",
        {
          name: formData.name,
          mobileNumber: formData.mobile,
          place: formData.city,
        }
      );

      // // Example usage:

      const baseUrl = "https://www.interiox.in/?ref=";

      setReferralLink(`${baseUrl}${response.data.referralCode}`);

      window.scrollTo(0, 0);

      setGeneratingLink(false);
    } catch (error) {
      setGeneratingLink(false);

      alert("Something went wrong. Please try again later");
    }
  };

  const shareReferralLink = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "InterioX",
          text: "Experience affordable luxury with InterioX! Turn your dream interior into reality in just 35 days. 🏠✨ Ready to create your ideal space? Contact now!",
          url: referralLink,
        })
        .then(() => {
          console.log("Referral link shared successfully.");
        })
        .catch((error) => {
          console.error("Error sharing referral link:", error);
        });
    } else {
      // Fallback for browsers that do not support the Share API
      alert("Your browser does not support sharing.");
    }
  };

  const [animIndex, setAnimIndex] = useState(1);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Calculate the index of the next circle
      const nextIndex = animIndex === 3 ? 1 : animIndex + 1;
      setAnimIndex(nextIndex);
    }, 5800);

    return () => {
      clearTimeout(timer);
    };
  }, [animIndex]);

  const Hello =
    "Hi. I am a [Your profession] from [Your city], and looking for partnering with you. Here are my details";

  const connectWARef = `https://wa.me/917892385607?text=${Hello}`;

  const handleWhatsappConnect = () => {
    window.location.href = connectWARef;

    ReactGA.event({
      category: "contact",
      action: "whatsapp_connect_clicked",
    });
  };

  return (
    <div>
      <Header />

      {!referralLink && (
        <div className='referral-page-container'>
          <div className='referral-page'>
            <span style={{ fontSize: 30 }}>Refer and Earn upto ₹20,000 🥳</span>

            {animIndex === 1 ? (
              <Lottie
                animationData={createLinkAnim}
                loop={false}
                className='anim-image'
              />
            ) : animIndex === 2 ? (
              <Lottie
                animationData={shareToFriendsAnim}
                loop={false}
                className='anim-image'
              />
            ) : (
              <Lottie
                animationData={earnMoneyAnim}
                loop={false}
                className='anim-image'
              />
            )}

            <span className='referral-subtitle'>
              <span
                className={
                  animIndex === 1
                    ? "highlight-earning-text"
                    : "normal-earning-text"
                }
              >
                Generate Link 🔗...
              </span>{" "}
              <span
                className={
                  animIndex === 2
                    ? "highlight-earning-text"
                    : "normal-earning-text"
                }
              >
                Share Link 🚀...
              </span>{" "}
              <span
                className={
                  animIndex === 3
                    ? "highlight-earning-text"
                    : "normal-earning-text"
                }
              >
                Earn Money* 💸
              </span>
            </span>

            <form className='referral-form' onSubmit={handleSubmit}>
              <div className='ref-form-group'>
                <label htmlFor='name'>Name:</label>
                <input
                  type='text'
                  id='name'
                  className='referral-input-field'
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='ref-form-group'>
                <label htmlFor='mobile'>Mobile Number:</label>

                <PhoneInput
                  placeholder='Enter valid mobile number'
                  value={formData.mobile}
                  onChange={(value) =>
                    setFormData({ ...formData, mobile: value })
                  }
                  defaultCountry='IN'
                />
              </div>

              <div className='ref-form-group'>
                <label htmlFor='mobile'>Confirm Mobile Number:</label>

                <PhoneInput
                  placeholder='Confirm Mobile Number'
                  value={confirmMobileNumber}
                  onChange={(value) => setConfirmMobileNumber(value)}
                  defaultCountry='IN'
                />
              </div>

              <span className='mobile-number-note'>
                Enter valid mobile number for UPI payment.
              </span>

              {/* <div className='ref-form-group'>
                <label>Choose City:</label>
                <div className='radio-group'>
                  <label>
                    <input
                      type='radio'
                      name='city'
                      value='Bengaluru'
                      checked={formData.city === "Bengaluru"}
                      onChange={handleChange}
                    />{" "}
                    Bengaluru
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='city'
                      value='Belagavi'
                      checked={formData.city === "Belagavi"}
                      onChange={handleChange}
                    />{" "}
                    Belagavi
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='city'
                      value='Kalaburagi'
                      checked={formData.city === "Kalaburagi"}
                      onChange={handleChange}
                    />{" "}
                    Kalaburagi
                  </label>
                </div>
              </div> */}

              <div className='agree-policy'>
                <input
                  type='checkbox'
                  id='agreeToPolicy'
                  name='agreeToPolicy'
                  checked={formData.agreeToPolicy}
                  onChange={handleChange}
                  required
                />
                <label htmlFor='agreeToPolicy'>
                  I have read and agree to the referral money policy.
                </label>
              </div>

              {count && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      fontSize: 45,
                      fontWeight: "bold",
                      color: "#d81b60",
                    }}
                  >
                    {count} 🤩
                    <br />
                    <span
                      style={{
                        fontSize: 18,
                        color: "#f06292",
                        fontWeight: "normal",
                      }}
                    >
                      Members Signed Up. Hurry..!
                    </span>
                  </span>
                </div>
              )}

              <button
                className='generate-link-button'
                disabled={generatingLink}
                style={{ backgroundColor: generatingLink ? "gray" : "#007bff" }}
              >
                {generatingLink ? "Generating Link..." : "Generate Link"}
              </button>
            </form>

            {/* Display referral link or success message here */}
          </div>
        </div>
      )}

      {referralLink && (
        <div className='success-message'>
          <div className='anim-image-container'>
            <Lottie
              animationData={successAnim}
              loop={true}
              className='anim-image'
            />
          </div>
          <p>
            Congratulations 🥳 <br /> Your Referral Link Is 👇
          </p>
          <input
            type='text'
            value={referralLink}
            readOnly
            onClick={(e) => e.target.select()}
          />

          <button onClick={shareReferralLink}>Share Link Anywhere</button>

          <Lottie
            animationData={socialMediaIcons}
            loop={false}
            className='anim-image'
          />
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "30px",
          textAlign: "center",
        }}
      >
        <h3>
          If you are an Architect, Civil Engineer, Real Estate Agent,
          Contractor, Builder, Or any agent please Whatsapp your details for
          long term partnership.
        </h3>

        <div className='contact-screen-method' style={{ cursor: "pointer" }}>
          <FaWhatsapp className='contact-screen-icon' />
          <span href={connectWARef} onClick={handleWhatsappConnect}>
            Chat On WhatsApp
          </span>
        </div>
      </div>

      <div className='referral-money-policy'>
        <h2>Referral Money Policy</h2>
        <p>
          By participating in our referral program, you agree to the following
          terms and conditions:
        </p>

        <h3>1. Eligibility</h3>
        <p>
          To be eligible for referral rewards, you must be a registered user of
          our platform.
        </p>

        <h3>2. Earn Money</h3>
        <p>
          You will earn referral rewards for every successful referral. A
          successful referral is defined as a new client who using your unique
          referral link, takes interior service from us. Or client mentions your
          referral code at the time of project agreement. <br /> <br /> If
          client contacts us with multiple referral links, the first referred
          person will get the referral reward.
          <br /> <br />{" "}
          <span style={{ color: "red" }}>
            NOTE: Keep Sharing Your Referral Code At least "Once A Week".
            <br /> <br /> The Automatic Referral Session will last up to 6 days,
            after which it expires. This means that if a client contacts us
            after the 7th day from their first visit to our website using your
            referral link, there's a chance your referral code might not be
            visible to them. But don't worry, they can simply mention your code,
            and you can still receive the reward when the project successfully
            converts. 😊
            <br />
            <br />
            But Don't Risk... Share More... Earn More.😉
          </span>
        </p>

        <h3>3. Reward Amount</h3>
        <p>
          You'll earn upto 20,000 Indian Rupees based on the Project Quotation
          Amount Before GST.
          <ul>
            <li>
              Project worth up to 5 lakhs: Approx. reward of 10,000 Indian
              Rupees 2% commission
            </li>
            <li>Projects above 5 lakhs: Higher benefits with 3% commission</li>
          </ul>
        </p>

        <h3>4. Payment</h3>
        <p>
          Payments are typically credited to your account using UPI Payment, So
          Please add a valid mobile number. We will not make any payment to your
          alternate number if the given mobile number is not valid.
        </p>

        <h3>5. Fraudulent Activity</h3>
        <p>
          Any fraudulent activity, including but not limited to creating fake
          accounts or engaging in deceitful practices, will result in the
          disqualification of referral rewards.
        </p>

        <h3>6. Modification and Termination</h3>
        <p>
          We reserve the right to modify or terminate the referral program at
          any time without prior notice. Changes to the program will be
          effective immediately upon posting.
        </p>

        <h3>7. Contact Us</h3>
        <p>
          If you have any questions or concerns about the referral program or
          this policy, please <a href='/contact'>contact us</a>.
        </p>

        <p>
          By participating in the referral program, you acknowledge that you
          have read and agree to this policy.
        </p>
      </div>

      {/* footer */}
    </div>
  );
};

export default ReferralPage;
