import React, { useEffect, useRef, useState } from "react";
import "./Exhibition.css";

//
import image1 from "../assets/grid-images/grid-image-1.jpg";
import image2 from "../assets/grid-images/grid-image-2.jpg";
import image4 from "../assets/grid-images/grid-image-4.jpg";
import image8 from "../assets/grid-images/grid-image-8.jpg";
import image10 from "../assets/grid-images/grid-image-10.jpg";
import image11 from "../assets/grid-images/grid-image-11.jpg";
import image12 from "../assets/grid-images/grid-image-12.jpg";
import image14 from "../assets/grid-images/grid-image-14.jpg";

import image15 from "../assets/grid-images/grid-image-15.jpg";

import image16 from "../assets/grid-images/grid-image-16.jpg";

import image17 from "../assets/grid-images/grid-image-17.jpg";

import image18 from "../assets/grid-images/grid-image-18.jpg";
import image19 from "../assets/grid-images/grid-image-19.jpg";
import image21 from "../assets/grid-images/grid-image-21.jpg";
import image22 from "../assets/grid-images/grid-image-22.jpg";
import image23 from "../assets/grid-images/grid-image-23.jpg";
import image24 from "../assets/grid-images/grid-image-24.jpg";

import ImageModal from "./ImageModal";

const Exhibition = () => {
  //

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Update window width when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener to window resize event
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const images = [
    { src: image23 },
    { src: image24 },
    {
      src: image1,
    },
    {
      src: image2,
    },
    {
      src: image4,
    },
    {
      src: image8,
    },

    {
      src: image10,
    },
    {
      src: image11,
    },
    {
      src: image12,
    },
    {
      src: image14,
    },
    {
      src: image15,
    },
    {
      src: image16,
    },
    { src: image17 },
    { src: image18 },
    { src: image19 },
    { src: image21 },
    { src: image22 },
  ];

  useEffect(() => {
    // Preload images
    const preloadImages = async () => {
      try {
        await Promise.all(
          images.map((image) => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = image.src;
              img.onload = resolve;
              img.onerror = reject;
            });
          })
        );
        setIsLoaded(true);
      } catch (error) {
        console.error("Error preloading images:", error);
      }
    };

    preloadImages();
  }, [images]);

  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= windowWidth * 0.6; // Adjust the scroll distance as needed
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += windowWidth * 0.6; // Adjust the scroll distance as needed
    }
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className='exhibition-container'>
      {isLoaded ? (
        <>
          <div className='image-grid' ref={scrollContainerRef}>
            {images.map((image, index) => (
              <div
                className='image-item'
                key={index}
                onClick={() => handleImageClick(image)}
              >
                <img src={image.src} />
              </div>
            ))}
          </div>
          {windowWidth > 480 && (
            <div className='scroll-button left-button' onClick={scrollLeft}>
              &lt;
            </div>
          )}
          {windowWidth > 480 && (
            <div className='scroll-button right-button' onClick={scrollRight}>
              &gt;
            </div>
          )}
        </>
      ) : (
        <div className='loading-message'>Loading images...</div>
      )}

      {selectedImage && (
        <ImageModal imageUrl={selectedImage.src} onClose={closeModal} />
      )}
    </div>
  );
};

export default Exhibition;
